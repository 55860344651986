@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  * {
    @apply outline-none;
    -webkit-user-drag: none;
    -webkit-tap-highlight-color: transparent;
  }

  html {
    @apply antialiased overflow-hidden h-full leading-7;
  }

  body {
    @apply overflow-auto h-full;
  }
}
